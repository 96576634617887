/* The proper spacing to be provided in the answer box */
/* This is the answer box you can modify the background color property to change the color of the answer box. */

/* This is the navbar code that styles it . You can modify the styles as needed to fit your design: */
.custom-navbar {
  width: 100vw; /* Ensure full viewport width */
  left: 0; /* Align to the left edge */
  right: 0; /* Align to the right edge */
  padding: 5px 20px; /* Add padding to the top and bottom */
  margin-bottom: 40px;
  border-radius: 20px; /* Add rounded corners */
  background-color: rgba(
    0,
    0,
    0,
    0.2
  ); /* Semi-transparent black  (Last value is opacity and the others are the colors)*/
  z-index: 100; /* Ensure the navbar is above other elements */
}

.search-input {
  width: 1000px;
  padding-right: 50px;
  padding-left: 20px;
  box-sizing: border-box;
  color: #0d6efd; /*Color of the text in the search bar */
  border-radius: 100px;
  padding-top: 1px;
  padding-bottom: 1px;
}

/* /////////////////////////////  I HAVE MOVED THE REQUIRED STYLINGS ALONG WITH COMMENTS TO THE TOP FOR EASY CHANGE /////////////////////////////// */

/* glassmorphism effect acheived in body tag */
body {
  backdrop-filter: blur(10px) saturate(200%);
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  background-color: rgba(17, 25, 40, 0.62);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* Unspecified fix to center the answer box (Thought it would help) */
.results-answer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.form-button {
  background-color: #0d6efd;
  padding: 10px 30px;
  width: auto;
}
.form-button:hover {
  background-color: #0b5ed7;
}

.questlogo {
  height: 50px;
  width: 100px;
  object-fit: contain;
}

.search-wrapper {
  margin-top: 50px;
  position: relative;
  display: flex;
  align-items: center;
}

.search-button {
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0 20px; /* Adjust based on your design */
}
.search-icon {
  font-size: 0.9em; /* Adjust based on your design */
}
