input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000000s ease-in-out 0s;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main-container {
  display: flex;
  min-width: 37.5rem;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form-input {
  width: 75%;
  padding: 10px;
  font-size: 20px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.form-button {
  padding: 10px 20px;
  margin: 15px 0 0 0;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: #3498db;
  color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.loader-container {
  margin-top: 10px;
}

.results-answer {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  text-align: center;
  width: 75%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button-container {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
}

.button-container a {
  display: block;
  width: 100%;
}

.button-container a:first-child {
  margin-right: 10px;
}

.button-container button {
  padding: 10px 20px; /* Adjust the padding as needed */
  border-radius: 5px; /* Rounded corners */
  border: 1px solid #ccc; /* Outline for outline buttons */
  background-color: #007bff; /* Default background color */
  color: white; /* Default text color */
  cursor: pointer;
}
