input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

.wrapper {
    position: relative;
    width: 420px;
    height: 450px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, .1);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color: #54a5f1;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: height .2s ease;
    margin-top: 120px;
}

.wrapper.active {
    height: 520px;
}

.wrapper .form-box {
    width: 100%;
    padding: 40px;
}
.wrapper .form-box.login,
.wrapper .form-box.register {
    transition: translate .18s ease;
    translate: 0;
}

.wrapper.active .form-box.login,
.wrapper.active .form-box.register {
    transition: none;
    translate: -400px;
}

form h1 {
    font-size: 36px;
    text-align: center;
}

form .input-box {
    position: relative;
    width: 100%;
    height: 50px;
    margin: 30px 0;
}

.input-box input {
    width: 100%;
    height: 100%;
    background: 'transparent';
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, .1);
    border-radius: 40px;
    font-size: 16px;
    color: #2729b8;
    padding: 20px 45px 20px 20px;
}

.input-box input::placeholder {
    color: #3d1ec7;
}

.input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    translate: 0 -50%;
    font-size: 16px;
}

form .remember-forgot {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
}

.remember-forgot label input {
    accent-color: #fff;
    margin-right: 4px;
}

.remember-forgot a {
    color: #fff;
    text-decoration: none;
}

.remember-forgot a:hover {
    text-decoration: underline;
}

form button {
    width: 100%;
    height: 45px;
    background: #2546da;
    border: none;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #d2cbe4;
    font-weight: 700;
}

form .register-link {
    font-size: 14.5px;
    text-align: center;
    margin: 20px 0 15px;
}

.register-link p a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}

.register-link p a:hover {
    text-decoration: underline;
}

